import Header from "./Header.tsx"

export default function HomePage() {
  return (
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1"> 
      <div style="background-image: url('bg.jpg')" class="relative bg-no-repeat bg-cover px-4 flex items-center justify-center flex-col pb-2"
      >
      <div class="flex items-center justify-center flex-col w-full">
      <Header />
        <div class="w-full text-center z-10 px-6 sm:px-10 lg:px-20 xl:px-64 2xl:px-56 pt-32 pb-48 sm:pb-44 md:pt-40 md:pb-48 lg:pt-40 xl:pb-40 2xl:pt-56 2xl:pb-96 ">
            <div class="text-center">
              <h1 class="text-white text-5xl font-extrabold leading-[3.75rem]">
                An easier way for charities to distribute funds
              </h1>
              <p class="text-lg leading-6 mt-4 text-gray-100">
                Replace clunky vouchers with chairty-branded prepaid cards
                to save you time and money on administration
              </p>
              {/* <a href="#contact-form" class="px-10 py-4 border-white border-2 text-white rounded-full inline-block mt-8 font-semibold uppercase">Contact Us</a> */}
              <a href="/partneringwithwarmhearted" class="px-10 py-4 border-white border-2 text-white rounded-full inline-block mt-8 font-semibold uppercase">Contact Us</a>
            </div>
          </div>
      </div>
      </div>

      {/* container2 */}
      <div class="relative bg-gray-100 px-4 sm:px-8 lg:px-16 xl:px-40 2xl:px-64 pt-12 pb-20">
        <div class="text-center">
          <h1 class="text-4xl font-bold mb-2">Our solutions to your problems</h1>
          <p class="text-gray-500">Replace clunky vouchers with chairty-branded prepaid cards to save you<br/>time and money on administration</p>
        </div>
        <div class="grid gap-y-5 gap-x-5 grid-cols-1 md:grid-cols-3 items-start justify-between mt-12">
          <div class="w-full bg-white shadow-lg rounded-l px-6 py-5 md:mx-2 lg:mx-4 h-full">
            <img src="/feature/realtime-reporting.webp" alt="" class="mx-auto w-full" />
            <h4 class="text-xl font-bold leading-tight mt-8">Real time reporting for improved governance</h4>
            <p class="text-gray-700 mt-2">Daily and monthly transaction data at your fingertips offering real 
            time insight into the business.</p>
            <p class="text-gray-700 mt-2">Greater transparency around programme participation and spend, for feeding back to donors to engage and strengthen relationships.</p>
          </div>

          <div class="w-full bg-white shadow-lg rounded-l px-6 py-5 md:mx-2 lg:mx-4 mt-4 md:mt-0 h-full">
            <img src="/feature/cardholder-management.webp" alt="" class="mx-auto w-full"/>
            <h4 class="text-xl font-bold leading-tight mt-8">Cumbersome voucher systems eliminated</h4>
            <p class="text-gray-700 mt-2">Improved member experience with a simple and convenient card for payment.</p>
            <p class="text-gray-700 mt-2">Efficient internal processes that reduce administration to free up resources to focus on member support.</p>
          </div>

          <div class="w-full bg-white shadow-lg rounded-lg px-6 py-5 md:mx-2 lg:mx-4 mt-4 md:mt-0 h-full">
            <img src="/feature/supplier-payments.webp" alt="" class="mx-auto w-full"/>
            <h4 class="text-xl font-bold leading-tight mt-8">Seamless supplier management</h4>
            <p class="text-gray-700 mt-2">Daily payments to suppliers handled by Warmhearted to eliminate time consuming invoice payments and reconciliation.</p>
            <p class="text-gray-700 mt-2">Responsive customer support and streamlined onboarding of specific suppliers ensure you have none of the day-to-day supplier management.</p>
          </div>
        </div>
      </div>
      {/* end */}

      {/* testimony */}
      <div class="relative bg-[#fa8a41] px-4 sm:px-8 lg:px-16 xl:px-40 2xl:px-64 py-10">
        <h1 class="text-4xl font-bold my-2 text-center text-white">Don't just take our word for it</h1>
        <div class="grid gap-y-5 gap-x-5 grid-cols-1 md:grid-cols-2 items-start justify-between mt-12">

          <div class="w-full bg-white shadow-lg rounded-l px-6 py-5 md:mx-2 lg:mx-4 mt-4 md:mt-0 h-full flex justify-between flex-col">
            <div>
            <p class="text-gray-700 mt-2">“We have built a tighter relationship between the charity and funded recipient, and improved transparency of programme usage. It’s an easy and user-friendly process for the patient and administratively easier for the charity to manage and report on funds used.”</p>
            </div>
            <div class="flex items-center">
              <img class="rounded-full max-w-[3rem] w-auto" src="/evangelia-henderson.webp" alt="Evangelia Henderson" />
              <div class="ml-4">
                <p class="font-bold">Evangelia Henderson</p>
                <p class="text-gray-500 text-sm">CEO, Breast Cancer Foundation</p>
              </div>          
            </div>
          </div>

          <div class="w-full bg-white shadow-lg rounded-lg px-6 py-5 md:mx-2 lg:mx-4 mt-4 md:mt-0 h-full">
            <div class="mb-8">
              <p class="text-gray-700 mt-2">“The prepaid card solution has proved to be a popular option for both our physiotherapy providers and their patients. It has reduced some of the administration for providers, and patients can easily use their cards at clinics to pay for rehab sessions. Having a branded card is a great reminder of where their funding has come from, and provides a great method to track where and when funds are used nationwide.</p>
              <p class="text-gray-700 mt-2">I would definitely recommend Warmhearted to other charities looking to support their members throughout NZ.”</p>
            </div>
            <div class="flex items-center">
              <img class="rounded-full max-w-[3rem] w-auto" src="/lou-james.webp" alt="Lou James" />
              <div class="ml-4">
                <p class="font-bold">Lou James</p>
                <p class="text-gray-500 text-sm">CEO, PINC & STEEL International</p>
              </div>          
            </div>
          </div>
        </div>
      </div>
      {/* end */}

      {/* Cards */}
      <div class="relative bg-white px-4 sm:px-8 lg:px-16 xl:px-40 2xl:px-64 py-10">
        <h1 class="text-4xl font-bold mt-10 mb-14 text-center text-black">Charities that use our prepaid cards everyday…
        </h1>
        <div class="flex flex-col md:flex-row w-full justify-around items-center">
          <img class="w-[90%] md:w-1/3" src="/pinc-steel.webp" alt="Pinc Steel Card" />
          <img class="w-[90%] md:w-1/3 my-2 md:mx-2 md:my-0" src="/breast-cancer-foundation.webp" alt="Breast Cancer Foundation Card" />
          {/* <img class="w-[90%] md:w-1/3" src="/prepaid-card.webp" alt="Prepaid Card" /> */}
        </div>
      </div>
      {/* end */}

      {/* Contact Form */}
      {/* <div id="contact-form" class="relative bg-gray-100 px-4 sm:px-8 lg:px-16 xl:px-40 2xl:px-64 py-10">
        <h1 class="text-4xl font-bold mt-4 mb-8 text-center">Looking for your own prepaid card solution? Get in touch, we'd love to hear from you…</h1>
        <form  class="flex flex-col max-w-[900px] mx-auto">
            <div class="grid grid-cols-2 gap-8">
              <div>
                <label htmlFor="name">Name</label>
                <input class="w-full pl-6 py-4 mt-1 border-2 border-gray-200" type="text" placeholder="Jane Doe" />
              </div>
              <div>
                <label htmlFor="company">Company</label>
                <input class="w-full pl-6 py-4 mt-1 border-2 border-gray-200" type="text" placeholder="Warmhearted" />
              </div>
            </div>
            <div class="mt-4">
              <label htmlFor="phone">Phone</label>
              <input class="w-full pl-6 py-4 mt-1 border-2 border-gray-200" type="text" placeholder="+64 421 754 5223" />
            </div>
            <div class="mt-4">
              <label htmlFor="email">Email</label>
              <input class="w-full pl-6 py-4 mt-1 border-2 border-gray-200" type="email" placeholder="example@example.com" />
            </div>
            <button class="mt-4 p-4 bg-[#fa8a41] text-white w-full font-bold text-lg" type="submit">Submit</button>
        </form>
      </div> */}
      {/* end */}
    </div>
  );
}